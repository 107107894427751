import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath, nextPagePath, humanPageNumber, numberOfPages,
  } = pageContext;

  return (
    <nav className="pagination" role="navigation">
      <div>
        {previousPagePath && (

          <Link to={`${previousPagePath}#main`} rel="prev">
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>

        )}
      </div>
      {numberOfPages > 1 && (
        <div className="pagination-location">
          Faqja
          {' '}
          {humanPageNumber}
          {' '}
          /
          {' '}
          {numberOfPages}
        </div>
      )}
      <div>
        {nextPagePath && (

          <Link to={`${nextPagePath}#main`} rel="next">
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        )}
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pagination;
