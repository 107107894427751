import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({
  type,
  title,
  text,
  children,
}) => (
  <div className={`alert ${type}`}>
    {title && <h3>{title}</h3>}
    <p>{text}</p>
    {children}
  </div>
);

Alert.defaultProps = {
  type: '',
  title: null,
  children: null,
};

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Alert;
