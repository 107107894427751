import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ src }) => (
  <div className="avatar">
    <img src={src} alt="" />
  </div>
);

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Avatar;
