import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Header = ({ siteTitle, navigation, isHome }) => {
  const [expanded, setExpanded] = useState(false);
  const [transparentHeader, setTransparentHeader] = useState(isHome);

  useEffect(() => {
    const handleScroll = () => setTransparentHeader(isHome && window.scrollY < 100);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`main-nav ${expanded ? 'expanded' : ''} ${transparentHeader ? 'home' : ''}`}>
      <div className="nav-blur" />
      <div className="nav-curtain" />

      <div className="container">
        <Link to="/">
          <h1 className="logo">{siteTitle}</h1>
        </Link>

        <div className="nav-links">
          <div className="page-links">
            {/* <Link to="/">Ballina</Link> */}

            {navigation.map(({ label, url }) => (
              <Link key={url} to={url}>{label}</Link>
            ))}
          </div>

          <div className="social-links">
            <Link to="https://www.facebook.com/BISDarelHadith" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link to="https://twitter.com/BISDarelHadith" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
            <Link to="https://www.instagram.com/BISDarelHadith" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
          </div>
        </div>

        <button
          type="button"
          className="icon menu-icon"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <div className="line1" />
          <div className="line2" />
        </button>
      </div>
    </nav>
  );
};

Header.defaultProps = {
  isHome: false,
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  navigation: PropTypes.arrayOf(PropTypes.object).isRequired,
  isHome: PropTypes.bool,
};

export default Header;
