import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ title }) => (
  <footer className="main-footer">
    <div className="container">
      <span>
        <small>{title}</small>
        <small> &copy; </small>
        <small>{new Date().getFullYear()}</small>
      </span>
    </div>
  </footer>
);

Footer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Footer;
