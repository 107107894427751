import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './Header';
import Footer from './Footer';
import '../../styles/App.scss';
import Logo from '../Logo';

const Layout = ({ children, isHome }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title,
        description
      }
    }
    allGhostSettings {
      nodes {
        navigation {
          label,
          url
        }
      }
    }
  }
`);

  const { title } = data.site.siteMetadata;
  const { navigation } = data.allGhostSettings.nodes[0];

  return (
    <>
      <Helmet
        title={title}
        defer={false}
      />

      {isHome && (
        <div className="hero">
          <Logo />
          <h1>Bashkësia Islame Selefije</h1>
          <p>Dar el Hadith</p>
        </div>
      )}

      <Header
        isHome={isHome}
        siteTitle={title}
        navigation={navigation}
      />

      <main
        id="main"
        className={isHome ? 'home' : ''}
      >
        {children}
      </main>

      <Footer title={title} />
    </>
  );
};

Layout.defaultProps = {
  isHome: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
};

export default Layout;
