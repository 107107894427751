import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Avatar from './Avatar';

const Card = ({
  className,
  image,
  tags,
  title,
  excerpt,
  avatar,
  author,
  date,
  postLink,
  authorLink,
}) => (
  <div className={`card ${className}`}>
    <Link to={postLink}>
      {image && (<img className="card-img" src={image} alt="" />)}
    </Link>

    <small className="tags">{tags.join(' • ')}</small>

    <Link to={postLink}>
      <h2 className="title">{title}</h2>
    </Link>

    {excerpt && <p className="excerpt">{excerpt}</p>}

    <footer>
      <Link to={`author/${authorLink}`}>
        <Avatar src={avatar} />
      </Link>

      <div className="author-date">
        <Link to={`author/${authorLink}`}>
          <p className="author">{author}</p>
        </Link>
        <span className="date">{date}</span>
      </div>
    </footer>
  </div>
);

Card.defaultProps = {
  tags: [],
  className: '',
  image: null,
  excerpt: null,
  avatar: null,
  postLink: '',
  authorLink: '',
};

Card.propTypes = {
  className: PropTypes.string,
  image: PropTypes.node,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  avatar: PropTypes.node,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  postLink: PropTypes.string,
  authorLink: PropTypes.string,
};

export default Card;
